<template>
  <div class="detail">
    <div class="title">
      <img src="../../public/images/asset/my-income/back.png" alt="" @click="$router.go(-1)">
      {{ $t('stake.desc100') }}
    </div>
    <div class="wrap info-list">
      <div class="info-item">
        <div class="til">{{$t('stake.desc73') }}</div>
        <div class="text">{{ info.earningDate }}</div>
      </div>
      <div class="info-item">
        <div class="til">{{ $t('stake.desc74') }}</div>
        <div class="text on">{{ common.cutXiaoNum1(info.earningTotalAmount) }}{{ info.earningCoinName }}</div>
      </div>
    </div> 
    <div class="wrap" v-for="item in info.voList" :key="item.id">
      <div class="item">
        <div class="til">{{incomeType==1?$t('stake.desc76'):$t('stake.desc103')}}</div> 
        <div class="text" v-if="incomeType==1">{{ item.investTotalAmount }} {{ item.investCoinName }}</div>
        <div class="text" v-else>{{ item.resourceUserId }}</div>
      </div>

      <div class="item" v-if="incomeType!=1">
        <div class="til">{{ $t('stake.desc78') }}</div>
        <div class="text">{{ item.resourceEarningTotalAmount }} {{ item.earningCoinName }}</div>
      </div>
      <!-- 利率 收益率-->
      <div class="item">
        <div class="til">{{ $t('stake.desc93') }}</div>
        <div class="text">{{ common.getValueMultip(item.earningRate,100) }}%</div>
      </div>
      <!-- 产品名称 -->
      <div class="item" v-if="incomeType==1">
        <div class="til">{{ $t('stake.desc48') }}</div>
        <div class="text">{{ item.productName }}</div>
      </div>
      <!-- 浮动利率 -->
      <div class="item" v-if="incomeType==1">
        <div class="til">{{ $t('stake.desc101') }}</div>
        <div class="text">{{ common.getValueMultip(item.earningRateAddition,100)}}%</div>
      </div>
      <!-- 奖励类型 -->
      <div class="item">
        <div class="til">{{ $t('stake.desc60') }}</div>
        <div class="text">{{ item.earningType==1?$t("stake.desc41"):(item.earningType==2?$t("stake.desc42"):(item.earningType==3?$t("stake.desc44"):$t("stake.desc43")))  }}</div>
      </div>
      <!-- 奖励数量 -->
      <div class="item">
        <div class="til">{{$t('stake.desc77')}}</div>
        <div class="text">{{ item.earningAmount }} {{ item.earningCoinName }}</div>
      </div>
     
    </div>
    <div class="no-data" v-if="showNull">
      <img src="../../public/images/no-data.png" alt="">
      <span>{{ $t('record.desc13') }}</span>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['assetType']),
  },
  data() {
    return {
      info: {},
      showNull: false,
      incomeType:1,
    }
  },
  mounted() {
    this.detailedInfo()
  },
  methods: {
    detailedInfo() {
      this.incomeType = this.$route.query.incomeType;
      let url = this.URL.stake.rewardsDetail
      this.$post(url, {
        sn: this.$route.query.sn,
        earningType:this.$route.query.incomeType
      }).then(res => {
        if(res.code == 0) {
          this.info = res.data;
          if (this.info.voList.length == 0) {
            this.showNull = true
          }
        } else {
          this.$message.error(res.message)
        }
      })
    },
  }
}
</script>

<style lang="less" scoped>
.detail {
  padding: 0 16px 16px;
  .title {
    position: relative;
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 12px;
    img {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 14px;
      height: 22px;
    }
  }
  .wrap {
    margin-bottom: 14px;
    background: #181818;
    border-radius: 8px;
    padding: 16px;
    &:last-child {margin-bottom: 0;}
    &.info-list {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
    .info-item {
      width: calc(50% - 8px);
      .til {
        font-size: 12px;
        font-weight: 400;
        color: #A8A8A8;
      }
      .text {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
        &.on {color: #C9FA5B;}
      }
    }
    .item {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      .til {
        font-size: 13px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
      }
      .text {
        font-size: 13px;
        font-weight: 500;
        color: #fff;
        text-align: right;
      }
    }
    .tips {
      padding-top: 16px;
      margin-top: 20px;
      border-top: 1px solid rgba(255, 255, 255, 0.05);
      font-size: 12px;
      font-weight: 400;
      color: #E15757;
    }
  }
}
.speed {
    width: max-content;
    margin-top: 12px;
    border-radius: 6px;
    background: rgba(201, 250, 91, 0.10);
    font-size: 13px;
    color: #C9FA5B;
    padding: 4px;
    line-height: 14px;

    img {
      width: 14px;
      height: 14px;
      margin-right: 4px;
    }
  }
</style>
